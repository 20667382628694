<template>
  <div>
    <h3>What is a Capacitor?</h3>
    <p>
      A capacitor is a device that stores energy in the form of an electric field. It comprises of two conducting plates
      carrying equal and opposite charges and separated by an insulating medium.
      The magnitude of the electric charge \(Q\) stored in the capacitor is proportional to the voltage difference \(V\) between the two conducting plates, i.e.,
      $$Q \propto V$$
      The proportionality constant is known as capacitance of the capacitor, and is denoted by \(C\).
    </p>
    $$Q=CV$$
    <h3>Series Combination</h3>
    <p>
      When two or more capacitors are connected in such a way that plates of adjacent capacitors are connected
      with each other i.e., only the first plate of first capacitor and second plate of the last capacitor is connected
      to the source, the combination is known as series.<br> In series combination the magnitude of charge on each
      capacitor is same. Consider \(n\) capacitors (\(C_1\),\(C_2\), … , \(C_n\) ) connected in series.
    </p>
    <ul class="a">
      <li>
        <h5>Charge on Capacitor</h5>
        <p> Because they are connected in series, all the capacitors carry same amount of electric charge. </p>
        $$Q=C_1V_1 = C_2 V_2 = .... = C_n V_n$$
        where \(V_n\) is the voltage difference across \(n^{th}\) capacitor.
      </li><li>
        <h5>Voltage Drops</h5>
        <p>
          Voltage difference across \(n^{th}\) capacitor is given as:
          $$V_n = Q/C_n$$
          Total voltage difference is the sum of voltage differences across individual capacitors, i.e.,
        </p>
        $$V=V_1+V_2+...+V_n$$
      </li>
      <li>
        <h5>Equivalent Capacitance</h5>
        <p> The equivalent capacitance C is given by: </p>
        $$\frac{1}{C_{eq}} = \frac{1}{C_1} + \frac{1}{C_2} +...+ \frac{1}{C_n}$$
      </li>
      </li>
    </ul>
    <h3>Parallel Combination</h3>
    <p>
      When two or more capacitors are connected in such a way that one plate of all the capacitors are
      connected to one point of the source and other plate of all the capacitors are connected to other single
      point of the source, the combination is known as parallel combination. In parallel combination, potential
      difference \(V\) is same across all the capacitors. Now consider \(n\) capacitors \(C_1\), \(C_2\), … , \(C_n\) connected in parallel. For such capacitors:
    </p>
    <ul class="a">
      <li>
        <h5>Charge on Capacitor</h5>
        <p>
          Charge on each capacitor (say \(Q_i\)) is different and is given as:
          $$Q_i = C_i V$$.
          <br>Total charge \(Q\) is the sum of individual charges on each capacitor and is given as:
        </p>
        $$Q=Q_1+Q_2+...+Q_n$$
      </li>
      <li>
        <h5>Voltage Drops</h5>
        <p>The potential difference across each capacitor is same as the source i.e.,</p>
        $$V_i=\frac{Q_i}{C_i}=V$$
      </li>
      <li>
        <h5>Equivalent Capacitance</h5>
        <p>The equivalent capacitance \(C_{eq}\) is given by:</p>
        $$C_{eq}=C_1 + C_2 + ...+ C_n$$
      </li>
    </ul>

    <v-layout justify-center="">
      <v-img contain=""
             src="assets/parallel.png"
             max-height="400px"
             max-width="400px"
             width="400px"
      />
    </v-layout>
    <h3 ref="playgraph">
      MagicGraph | Equivalent Capacitance Calculator
    </h3>
    <!--<p>This MagicGraph offers students a unique artificially intelligent-visually interactive assistant using which students can:</p>
<ul style="list-style-type: square;">
<li>configure an electric circuit of any complexity (series, parallel, or mixed).</li>
<li>find the equivalent resistance of the circuit.</li>
<li>determine the current circulating in the circuit.</li>
<li>calculate the voltage drop across each resistor.</li>
</ul> -->
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Capacitors',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Capacitors';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Resistor', img:'assets/number-1.svg', action: () => this.goto('ia')},
      {title: 'Resistors Connected in Parallel', img:'assets/number-2.svg', action: () => this.goto('ca')},
      {title: 'Resistors Connected in Series', img:'assets/number-3.svg', action: () => this.goto('iat')},
      {title: 'Graphical Interpretation',img:'assets/touch.svg', action: () => this.goto('graphical')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newPhys =true;
    this.$store.commit('navigation/replacePhys', newPhys);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
}
</script>
<style lang="scss">
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
