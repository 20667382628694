  import {
      makeResponsive,
      placeTitle,
      placeImage,
      placeInput,
      placeSlider,
      hoverMe,
      placeRec,
      hiddenPt,
      fixedPt,
      clearInputFields,
      dragMe,
      placeArrow,
      placeGravity,
      placeText,
      placeLine,
      placePoint,
      placeGlider,
      placeRuler,
      placeLeftText,
      placeCircle,
      placeAngle,
      placeDash,
      placeLabel
  } from '../../../../common/edliy_utils-circle';
  const Boxes = {
  box1: function () {
			      JXG.Options.board.minimizeReflow = 'none';
            JXG.Options.text.highlight =false;
            JXG.Options.text.fixed =true;
            JXG.Options.text.highlight =false;
            JXG.Options.text.cssStyle='fontFamily:Oswald',
            JXG.Options.image.highlight =false;
            JXG.Options.polygon.highlight =false;
            JXG.Options.line.highlight =false;
            JXG.Options.slider.highlight =false;
            var brd2 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-7, 8, 9, -8],keepaspectratio: true, axis:false, ticks:false, grid:true, pan:{enabled:false}, zoom:{enabled:false},  showCopyright:false, showNavigation:false});
            brd2.options.layer['line'] =2;
            brd2.options.layer['polygon'] =2;
            brd2.options.layer['image'] =8;
            makeResponsive(brd2);
            placeTitle(brd2, 'Capacitors', '1. Enter the capacitance 2. Drag the capacitors to complete the circuit');
            var a = brd2.create('slider',[[7,-4],[7,4],[0, 1, 2]],{unitLabel:'V',baseline:{strokeWidth:7, strokeColor:'grey'},highline:{strokeWidth:7, strokeColor:'black'}, name:'',size:8,face:'square', fillColor:'grey',strokeColor:'black', withTicks:false, label:{fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}, cssStyle:'fontFamily:Oswald;'}});
            var li1 = brd2.create('segment', [[0, -4], [0, 4]], {visible:false,strokeWidth:4, strokeColor:'orange', fixed:true});
            var li2 = brd2.create('segment', [[-2, -4], [-2, 4]], {visible:false,strokeWidth:4, strokeColor:'orange', fixed:true});
            var li3 = brd2.create('segment', [[-4, -4], [-4, 4]], {visible:false,strokeWidth:4, strokeColor:'orange', fixed:true});
            var li4 = brd2.create('segment', [[-6, -4], [-6, 4]], {visible:false,strokeWidth:4, strokeColor:'orange', fixed:true});
            brd2.create('segment', [[1, 4], [5, 4]], {visible:true, fixed:true, strokeWidth:4, strokeColor:'orange'});
            brd2.create('segment', [[5, -4], [5, 4]], {visible:true, fixed:true,strokeWidth:4, strokeColor:'orange'});
            brd2.create('segment', [[1, -4], [5, -4]], {visible:true, fixed:true, strokeWidth:4, strokeColor:'orange'});
            brd2.create('image', ['/assets/ui.svg', [4,-1],[2,2]],{fixed:true});
            var res1 = brd2.create('image', ['/assets/capacitor-red.svg', [-4.75,-6],[1.5,1.5]],{attractors:[li1, li2, li3, li4], attractorDistance:1.25, snatchDistance:0.75});
            var res2 = brd2.create('image', ['/assets/capacitor-blue.svg', [-1.75,-6],[1.5,1.5]],{attractors:[li1, li2, li3, li4], attractorDistance:1.25, snatchDistance:0.75});
            var res3 = brd2.create('image', ['/assets/capacitor-green.svg', [1.25,-6],[1.5,1.5]],{attractors:[li1, li2, li3, li4],  attractorDistance:1.25,  snatchDistance:0.75});
            var res4 = brd2.create('image', ['/assets/capacitor-yellow.svg', [4.25,-6],[1.5,1.5]],{attractors:[li1, li2, li3, li4],  attractorDistance:1.25,  snatchDistance:0.75});
            //
            hoverMe(brd2, res1, 'C1', -10, 30);
            hoverMe(brd2, res2, 'C2', -10, 30);
            hoverMe(brd2, res3, 'C3', -10, 30);
            hoverMe(brd2, res4, 'C4', -10, 30);
            brd2.create('text',[-5.25, -7, 'C_1 ='],{color:'red',anchorX:'middle',fontSize:function(){return Math.round(24*brd2.canvasWidth/800.)}, fixed:true});
            brd2.create('text',[-2.25, -7, 'C_2 ='],{color:'blue', anchorX:'middle',fontSize:function(){return Math.round(24*brd2.canvasWidth/800.)}, fixed:true});
            brd2.create('text',[ 0.75, -7, 'C_3 ='],{color:'green', anchorX:'middle',fontSize:function(){return Math.round(24*brd2.canvasWidth/800.)}, fixed:true});
            brd2.create('text',[ 3.75, -7, 'C_4 ='],{color:'black', anchorX:'middle',fontSize:function(){return Math.round(24*brd2.canvasWidth/800.)}, fixed:true});

            var uF1 = placeInput(brd2, -4.75, -7, 1);
            //brd1.create('input', [1, 0, '', 'C1'], {fixed: true, cssStyle: 'fontFamily:Oswald; width:7%; background-color:#2D42FD;border: 1px solid black;border-radius: 3.5px;', fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}, fixed:true,parse:true});
            var uF2 = placeInput(brd2, -1.75, -7, 1);
            //brd1.create('input', [4, 0, '', 'C2'], {fixed: true, cssStyle: 'fontFamily:Oswald; width:7%; background-color:#41C63C;border: 1px solid black;border-radius: 3.5px;', fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}, fixed:true,parse:true});
            var uF3 = placeInput(brd2, 1.25, -7, 1);
            //brd1.create('input', [7, 0, '', 'C3'], {fixed: true, cssStyle: 'fontFamily:Oswald; width:7%; background-color:#ECCC00;border: 1px solid black;border-radius: 3.5px;', fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}, fixed:true,parse:true});
            var uF4 = placeInput(brd2, 4.25, -7, 1);
            //brd1.create('input', [10, 0, '', 'C4'], {fixed: true, cssStyle: 'fontFamily:Oswald; width:7%; background-color:#FD2D2D;border: 1px solid black;border-radius: 3.5px;', fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}, fixed:true,parse:true});
            brd2.create('polygon', [[0.75, -4], [0.75, 4], [5, 4],[5, -4]], {fixed:true, vertices:{visible:false, fixed:true}, borders:{strokeColor:'orange', strokeWidth:4}, fillOpacity:0, visible:function(){if(res1.X()==0 || res2.X()==0 || res3.X()==0 || res4.X()==0){return true}},strokeWidth:4, strokeColor:'orange'});
            brd2.create('polygon', [[-1.25, -4], [-1.25, 4], [5, 4],[5, -4]], {fixed:true, vertices:{visible:false, fixed:true}, borders:{strokeColor:'orange', strokeWidth:4}, fillOpacity:0,visible:function(){if(res1.X()==-2 || res2.X()==-2 || res3.X()==-2 || res4.X()==-2){return true}},strokeWidth:4, strokeColor:'orange'});
            brd2.create('polygon', [[-3.25, -4], [-3.25, 4], [5, 4],[5, -4]], {fixed:true, vertices:{visible:false, fixed:true}, borders:{strokeColor:'orange', strokeWidth:4}, fillOpacity:0,visible:function(){if(res1.X()==-4 || res2.X()==-4 || res3.X()==-4 || res4.X()==-4){return true}},strokeWidth:4, strokeColor:'orange'});
            brd2.create('polygon', [[-5.25, -4], [-5.25, 4], [5, 4],[5, -4]], {fixed:true, vertices:{visible:false, fixed:true}, borders:{strokeColor:'orange', strokeWidth:4}, fillOpacity:0,visible:function(){if(res1.X()==-6 || res2.X()==-6 || res3.X()==-6 || res4.X()==-6){return true}},strokeWidth:4, strokeColor:'orange'});
            //TRY ME BUTTON
            var k =0;
            var km = function(){k=10000000;if(res1.X()==0 || res2.X()==0 || res3.X()==0 || res4.X()==0){k = 0;};if (res1.X()==0){k += 1/uF1.Value();}; if (res2.X()==0){k += 1/uF2.Value();}; if (res3.X()==0){k +=1/uF3.Value();}; if (res4.X()==0){k+=1/uF4.Value();}; return k;};
            var lm = function(){k=10000000;if(res1.X()==-2 || res2.X()==-2 || res3.X()==-2 || res4.X()==-2){k = 0;};if (res1.X()==-2){k += 1/uF1.Value();}; if (res2.X()==-2){k += 1/uF2.Value();}; if (res3.X()==-2){k += 1/uF3.Value()}; if (res4.X()==-2){k+= 1/uF4.Value()}; return k;};
            var rm = function(){k=10000000;if(res1.X()==-4 || res2.X()==-4 || res3.X()==-4 || res4.X()==-4){k = 0;};if (res1.X()==-4){k += 1/uF1.Value();}; if (res2.X()==-4){k += 1/uF2.Value();}; if (res3.X()==-4){k += 1/uF3.Value()}; if (res4.X()==-4){k+= 1/uF4.Value()}; return k;};
            var sm = function(){k=10000000;if(res1.X()==-6 || res2.X()==-6 || res3.X()==-6 || res4.X()==-6){k = 0;};if (res1.X()==-6){k += 1/uF1.Value();}; if (res2.X()==-6){k += 1/uF2.Value();}; if (res3.X()==-6){k += 1/uF3.Value()}; if (res4.X()==-6){k+=1/uF4.Value()}; return k;};
//
            brd2.create('text',[-2., 5, function(){return 'C_{eq} = '+ (1./km() + 1./lm() + 1./rm()+ 1./sm()).toFixed(2)+' μF';}],{visible:true,fontSize:function(){return Math.round(24*brd2.canvasWidth/800.)}});
            brd2.create('text',[2., 5, function(){return 'Q_{tot} = '+ (a.Value()*(1./km() + 1./lm() + 1./rm()+ 1./sm())).toFixed(2)+' μC';}],{visible:true,fontSize:function(){return Math.round(24*brd2.canvasWidth/800.)}});

//
          //  brd2.create('text',[4., 5, '&infin;'],{visible:function(){if(km()>4){return true}},CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd2.canvasWidth/800.)}, fixed:true});
/*
            var tryMe = brd2.create('image', ['assets/test.svg', [0, 5], [1,1]], {visible: true, fixed: true});
            tryMe.on('up', qTotCeq);
            function qTotCeq(){

                vectoruF0=[uF1.Value(),uF2.Value(),uF3.Value(),uF4.Value()];

                brd1.removeObject(txtQtot);
                brd1.removeObject(txtCapeq);

                vectorCap1 = [];
                vectoruF1 = [];
                vectorEqSerie = [];

                for (var i=0 ; i < vectorCap0.length ; i = i +1 ){

                    if( vectorCap0[i].Y()>3 && vectorCap0[i].Y()<10.25 && vectorCap0[i].X()<=8 && vectorCap0[i].X()>=2 && vectorCap0[i].X()>=2 ){
                        vectorCap1.push(vectorCap0[i]);
                        vectoruF1.push(vectoruF0[i]);
                    }
                }

                for (var i=0 ; i < vectorCap1.length ; i = i +1 ){
                    if (vectorCap1[i]!= null){

                        var capser=[vectoruF1[i]]
                        for(j=i+1; j< vectorCap1.length; j += 1){
                            if( vectorCap1[j] != null && vectorCap1[i].X() == vectorCap1[j].X() ){
                                capser.push(vectoruF1[j]);
                                vectorCap1[j]=null;

                            }
                        }

                        var capeq = CalculateCapSerial(capser);
                        vectorEqSerie.push(capeq);

                    }
                }

                var CapTotal= 0;
                for (i=0;i< vectorEqSerie.length;i++){
                    CapTotal = CapTotal + parseFloat(vectorEqSerie[i]);
                }
                var qTotal = CapTotal*voltage.Value();

                //Texts
                var txt1 = brd1.create('text', [3, 12.5, ' Q_t =  '],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}},);
                var txt11 = brd1.create('text', [5, 12.5, ' mC  '],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}},);

                var txt2 = brd1.create('text', [10, 12.5, ' C_e_q =  '],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}},);
                var txt22 = brd1.create('text', [12, 12.5, ' μF '],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}},);

                txtQtot = brd1.create('text', [4, 12.5, qTotal ],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}},);
                txtCapeq = brd1.create('text', [11, 12.5, CapTotal],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}},);

                //return [ CapTotal, qTotal ];
            };*/
    },
}
export default Boxes;
