import { render, staticRenderFns } from "./Resistor.vue?vue&type=template&id=0c75f212&"
import script from "./Resistor.vue?vue&type=script&lang=js&"
export * from "./Resistor.vue?vue&type=script&lang=js&"
import style0 from "./Resistor.vue?vue&type=style&index=0&id=0c75f212&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports